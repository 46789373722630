// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import jwt_decode from "jwt-decode";


export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(localStorage.getItem('auth_user'))
  },
  reducers: {
    setUser: (state, action) => {
      if( action.payload ){
        const user = jwt_decode(action.payload);
        user.token = action.payload;
        localStorage.setItem('auth_user', JSON.stringify(user))
        state.user = user;
      } else {
        state.user = null
        localStorage.removeItem('auth_user')
      }
    },
  }
})

export const {
  setUser,
} = authSlice.actions

export default authSlice.reducer
